import React, { useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";

const UploadTaxCodes = () => {
  const [file, setFile] = useState(null);
  const [jsonData, setJsonData] = useState([]);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleFileUpload = async () => {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const formattedData = jsonData.slice(1).map((row) => ({
        tax_code: row[0],
        description: row[1],
        additional_info: row[2],
        taxable: true, // Adjust based on your logic
      }));

      setJsonData(formattedData);
    };

    reader.readAsBinaryString(file);
  };

  const handleSubmit = () => {
    const siteConfig = process.env.REACT_APP_SITE_CONFIG
      ? JSON.parse(process.env.REACT_APP_SITE_CONFIG)
      : null;
    console.log(siteConfig);
    const url = siteConfig
      ? `${siteConfig.apiUrl}/taxes/api/sg_tax_codes/import`
      : `
      http://localhost:3004/taxes/api/sg_tax_codes/import`;
    console.log(url);
    axios
      .post(url, { data: jsonData })
      .then((response) => {
        console.log("Data successfully sent to server:", response.data);
      })
      .catch((error) => {
        console.error("Error sending data to server:", error);
      });
  };

  return (
    <div>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
      <button onClick={handleFileUpload}>Preview JSON</button>
      <button onClick={handleSubmit} disabled={jsonData.length === 0}>
        Upload
      </button>
      <pre>{JSON.stringify(jsonData, null, 2)}</pre>
    </div>
  );
};

export default UploadTaxCodes;
