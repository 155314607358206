import React from "react";

import Header from "./Header";
import UserProfile from "../profile/UserProfile"; // Import UserProfile
import Home from "./Home";

const Dashboard = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default Dashboard;
