import React from "react";

const Home = () => {
  return (
    <>
      <div>
        <h2></h2>
      </div>
    </>
  );
};
export default Home;
