import React, { useState } from "react";
import * as XLSX from "xlsx";

const ExcelToJsonConverter = () => {
  const [jsonData, setJsonData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    // Ensure that a file is selected and it's either .xlsx or .csv
    if (!file) {
      setError("No file selected");
      return;
    }

    const fileType = file.type;
    if (
      fileType !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      fileType !== "text/csv"
    ) {
      setError("Please upload a valid .xlsx or .csv file");
      return;
    }

    setError(null); // Clear previous errors
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const data = event.target.result;

        if (fileType === "text/csv") {
          // Handle CSV files
          const workbook = XLSX.read(data, { type: "binary" });
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonResult = XLSX.utils.sheet_to_json(sheet, { raw: false });
          setJsonData(jsonResult);
        } else {
          // Handle Excel (.xlsx) files
          const arrayBuffer = new Uint8Array(data);
          const workbook = XLSX.read(arrayBuffer, { type: "array" });
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonResult = XLSX.utils.sheet_to_json(sheet, { raw: false });
          setJsonData(jsonResult);
        }
      } catch (e) {
        console.error("Error parsing the file:", e);
        setError("Error processing the file");
      }
    };

    // Read the file as binary for CSV, and ArrayBuffer for Excel files
    if (fileType === "text/csv") {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  const onSubmit = async () => {
    if (!jsonData) {
      setError("No data to upload");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const siteConfig = process.env.REACT_APP_SITE_CONFIG
        ? JSON.parse(process.env.REACT_APP_SITE_CONFIG)
        : null;

      const url = siteConfig
        ? `${siteConfig.apiUrl}/taxes/api/data_import/import`
        : `http://localhost:3004/taxes/api/data_import/import`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: jsonData }),
      });

      if (response.ok) {
        setSuccess(true);
        console.log("Data sent successfully");
      } else {
        const errorMessage = await response.text();
        setError(`Failed to send data: ${errorMessage}`);
        console.error("Failed to send data:", response.statusText);
      }
    } catch (err) {
      setError(`Error sending data: ${err.message}`);
      console.error("Error sending data:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileUpload} accept=".xlsx, .csv" />
      <button onClick={onSubmit} disabled={loading || !jsonData}>
        Submit
      </button>
      {loading && <div>Loading...</div>}
      {success && <div>Data uploaded successfully</div>}
      {error && <div style={{ color: "red" }}>{error}</div>}
      {jsonData && (
        <div>
          <h2>JSON Data:</h2>
          <pre>{JSON.stringify(jsonData, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default ExcelToJsonConverter;
